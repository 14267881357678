<template>
  <b-modal
    id="profile-corporate-modal"
    title="Edit Corporate Information"
    cancel-title="CANCEL"
    ok-title="SAVE"
    hide-header-close
    centered
    @ok="submit"
  >
    <div class="px-4">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Company Name"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.corporate_name.$anyError : null"
          >
            <b-form-input v-model="form.corporate_name" placeholder="Enter Company Name" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_name.required : null">
              company Name is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Address"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.corporate_address.$anyError : null"
          >
            <b-form-input v-model="form.corporate_address" placeholder="Enter Address" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_address.required : null">
              address is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <div class="mb-4">
            <b-form-group
              label="City"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.corporate_city.$anyError : null"
            >
              <b-form-input v-model="form.corporate_city" placeholder="Enter City" />
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_city.required : null">
                city is required
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="6">
          <div class="mb-4">
            <b-form-group
              label="Country"
              class="mb-6"
              :state="$v.form.$anyDirty ? !$v.form.corporate_country.$anyError : null"
            >
              <b-form-select
                v-model="form.corporate_country"
                :options="countryList"
                :state="$v.form.$anyDirty ? !$v.form.corporate_country.$anyError : null"
                @change="onCountryChange"
              >
                <template #first>
                  <b-form-select-option :value="null">
                    Select Country
                  </b-form-select-option>
                </template>
              </b-form-select>
              <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_country.required : null">
                country is required
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Telephone"
            class="mb-6"
            :state="$v.form.$anyDirty ? !$v.form.corporate_telphone.$anyError : null"
          >
            <b-form-input v-model="form.corporate_telphone" placeholder="Enter Telephone" />
            <b-form-invalid-feedback :state="$v.form.$anyDirty ? $v.form.corporate_telphone.required : null">
              telephone is required
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import countryList, { getCountryCode } from '@/common/countryList'
import { updateUserCorporateData } from '@/api/user'

export default {
  name: 'InfoModal',
  data () {
    return {
      form: {
        corporate_name: '',
        corporate_country: '',
        corporate_city: '',
        corporate_address: '',
        corporate_telphone: ''
      }
    }
  },
  validations () {
    return {
      form: {
        corporate_name: { required },
        corporate_country: { required },
        corporate_city: { required },
        corporate_address: { required },
        corporate_telphone: { required }
      }
    }
  },
  computed: {
    countryList () {
      return countryList
    }
  },
  methods: {
    show (data) {
      this.$bvModal.show('profile-corporate-modal')
      this.form = data
      this.$v.form.$reset()
    },
    hide () {
      this.$bvModal.hide('profile-corporate-modal')
    },
    onCountryChange (value) {
      this.form.corporate_telphone = `+${getCountryCode(value)}`
    },
    submit (e) {
      e.preventDefault()
      this.$v.form.$touch()
      if (this.$v.form.$anyError) return
      updateUserCorporateData(this.form).then(() => {
        this.$emit('update')
        this.hide()
      })
    }
  }
}
</script>
