<template>
  <div>
    <b-row>
      <b-col cols="4">
        <div class="areawrap">
          <div class="d-flex justify-content-between align-items-end mb-4">
            <h2 class="mb-0">
              Personal Information
            </h2>

            <b-dropdown variant="link" toggle-class="text-decoration-none" no-caret right>
              <template #button-content>
                <font-awesome-icon :icon="['fas', 'ellipsis-h']" fixed-width />
              </template>
              <b-dropdown-item href="#" @click.prevent="openInfoModal">
                Edit Information
              </b-dropdown-item>
              <b-dropdown-item href="#" @click.prevent="$refs.changePasswordModal.show()">
                Change Password
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <b-row class="align-items-center">
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Full Name
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ profileData.owner_name }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Department
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                -
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Role
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                Admin
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Email
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ profileData.owner_email }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Phone Extension
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ profileData.owner_extension }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Status
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span v-if="profileData.status === 0" class="d-inline-block bg-primary text-white rounded-pill px-2 py-0">
                Pending
              </span>
              <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0">
                Active
              </span>
            </b-col>
          </b-row>
        </div>
      </b-col>

      <b-col cols="4">
        <div class="areawrap">
          <div class="d-flex justify-content-between align-items-end mb-4">
            <h2 class="mb-0">
              Corporate Information
            </h2>
            <b-link class="text-gray-500" href="#" @click.prevent="openCorporateModal">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
            </b-link>
          </div>

          <b-row class="align-items-center">
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Company Name
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ profileData.corporate_name }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Telephone
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ profileData.corporate_telphone }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Address
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ profileData.corporate_city }},
                {{ profileData.corporate_address }}
              </span>
            </b-col>
            <b-col cols="4" class="mb-5">
              <span class="font-size-sm text-primary">
                Country
              </span>
            </b-col>
            <b-col cols="8" class="mb-5">
              <span>
                {{ country }}
              </span>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <info-modal ref="infoModal" @update="getUserProfileData" />
    <corporate-modal ref="corporateModal" @update="getUserProfileData" />
    <change-password-modal ref="changePasswordModal" />
  </div>
</template>

<script>
import countryList from '@/common/countryList'
import infoModal from '@/components/profile/infoModal'
import corporateModal from '@/components/profile/corporateModal'
import ChangePasswordModal from '@/components/profile/changePasswordModal'
import { getUserProfileData } from '@/api/user'

export default {
  name: 'Profile',
  components: {
    infoModal,
    corporateModal,
    ChangePasswordModal
  },
  data () {
    return {
      profileData: {}
    }
  },
  computed: {
    country () {
      return countryList.find(country => country.value === this.profileData.corporate_country)?.text
    }
  },
  created () {
    this.getUserProfileData()
  },
  methods: {
    getUserProfileData () {
      getUserProfileData().then(res => {
        this.profileData = res.data.data
      })
    },
    openInfoModal () {
      const data = {
        owner_name: this.profileData.owner_name,
        owner_extension: this.profileData.owner_extension
      }
      this.$refs.infoModal.show(data)
    },
    openCorporateModal () {
      const data = {
        corporate_name: this.profileData.corporate_name,
        corporate_country: this.profileData.corporate_country,
        corporate_city: this.profileData.corporate_city,
        corporate_address: this.profileData.corporate_address,
        corporate_telphone: this.profileData.corporate_telphone
      }
      this.$refs.corporateModal.show(data)
    }
  }
}
</script>
